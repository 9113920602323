const Header = () => {
    return (
        <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
            <a href={process.env.PUBLIC_URL} className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                <img src={process.env.PUBLIC_URL + '/logo_topcam400x172.png'} className="App-logo" alt="logo" width="200px" height="auto"/>
            </a>
        </header>
    );
}

export default Header