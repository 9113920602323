import Header from './components/Header.js'
import Footer from './components/Footer.js'
import CamList from './components/CamList.js';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
    return(
        <div className="App">
            {/* Header */}
            <div className="container">
                <Header />
            </div>
            {/* Content */}
            <div className="container">
                <CamList />
            </div>
            {/* Footer */}
            <div className="container">
                <Footer />
            </div>
        </div>
    );
}

export default App;