import React, { useState } from 'react';
import { Button, Modal, Badge } from 'react-bootstrap';
import ReactPlayer from 'react-player'

const CamItem = (props) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var camOutput = <img src="https://placekitten.com/1280/720" alt="Webcam" width="100%"/>;

    if (props.type === "live") {
        camOutput = <ReactPlayer url={props.url} playing muted controls playbackRate="30" width="100%" height="auto" />
    } else {
        camOutput = <img src={props.url} alt="Webcam" width="100%"/>;
    }

    return (
        <div>
        <Button variant="outline-primary" onClick={handleShow}>
            {props.name}, {props.location} {props.new === true && <Badge pill bg="secondary" text="light">New</Badge>}
        </Button>

        <Modal show={show} onHide={handleClose} dialogClassName="cam-modal">
            <Modal.Header closeButton>
                <Modal.Title>{props.name}, {props.location}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {camOutput}
            </Modal.Body>
        </Modal>
        </div>
    )
}

export default CamItem;