import CamItem from './CamItem'
import camArray from './data/cams.json'

import React from 'react';

const CamList = () => {

    const listLiveItems = camArray.map(
        (cams) => {
            if(cams.type === "live" && cams.disabled == null) {
                return(
                    <dt><CamItem name={cams.name} type={cams.type} location={cams.location} url={cams.url} new={cams.new}/></dt>
                )
            } else
            return(
                null
            )
        }
    );
    const listStaticItems = camArray.map(
        (cams) => {
            if(cams.type === "static" && cams.disabled == null) {
                return(
                    <dt><CamItem name={cams.name} type={cams.type} location={cams.location} url={cams.url} new={cams.new}/></dt>
                )
            } else
                return(
                    null
                )
        }
    );
    
    return (
        <div>
            <h2>Livestream Kameras</h2>
                <dl>
                    {listLiveItems}
                </dl>
            <h2>Statische Kameras</h2>
                <dl>
                    {listStaticItems}
                </dl>
        </div>
    )
}

export default CamList
